import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => (
  <>
    <Layout
      headerComponent={
        <header className="header pt-8 pb-4 bg-dark text-white text-center">
          <div className="container">
            <h1>Contact Us</h1>
            <p className="lead-2 mt-6">
              Our Customer Service team is here to help. Please, submit the form
              below and we will get back to you as soon as possible.
            </p>
          </div>
        </header>
      }
    >
      <section className="section py-7">
        <div className="container">
          <form
            className="row gap-y"
            action="../assets/php/sendmail.php"
            method="POST"
            data-form="mailer"
          >
            <div className="col-lg-7">
              <h3 className="fw-600 mb-0">Email us below</h3>
              <br />
              <div className="alert alert-success d-on-success">
                We received your message and will contact you back soon.
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    name="firstName"
                    placeholder="First name"
                  />
                </div>
                <div className="form-group col-md-3">
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    className="form-control form-control-lg"
                    type="email"
                    name="email"
                    placeholder="Email address"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    className="form-control form-control-lg"
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                  />
                </div>
                <div className="form-group col-md-6">
                  <select className="form-control form-control-lg">
                    <option selected="selected">Select a topic</option>
                    <option>I need help with my Borrower application</option>
                    <option>I need help with my Guarantor application</option>
                    <option>I need help with finding a Guarantor</option>
                    <option>I need help with making a payment</option>
                    <option>I have other questions</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control form-control-lg"
                  rows={4}
                  placeholder="How can we help you?"
                  name="message"
                  defaultValue={""}
                />
              </div>
              <button
                className="btn btn-xl btn-rounded btn-danger"
                type="submit"
              >
                Send message
              </button>
            </div>
            <div className="col-lg-5 ml-auto lead-2">
              <hr className="d-lg-none" />
              <h3 className="fw-600 mb-0">Other ways to contact us</h3>
              <br />
              <p>
                <b>Email:</b>{" "}
                <a href="mailto:info@trustic.com" target="_blank">
                  info@trustic.com
                </a>
              </p>
              <p>
                <b>Call:</b> (888) 429-0480
              </p>
              <p>
                <b>Monday - Friday:</b>
                <br />
                7:00am - 5pm Central
              </p>
            </div>
          </form>
        </div>
      </section>
    </Layout>
    <Seo
      title="Trustic | Contact"
      description="Don’t let your credit history to hold you back. Borrow affordably with a guarantor. "
    />
  </>
)

export default Contact
